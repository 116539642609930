<template>
    <div class="create_new_category">        
        <validation-observer ref="personalInfoForm">
        <b-card>
            <b-row>
                <b-col>
                    <validation-provider
                            #default="{ errors }"
                            name="Module Name"
                            rules="required"
                    >
                    <b-form-group label="Module Name">
                        <b-form-input name='moduleName' :state="errors.length > 0 ? false:null" :disabled='isSpinner' v-model='moduleData.name' placeholder="Enter Text"/>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col>
                    <validation-provider
                            #default="{ errors }"
                            name="Selection of Student Manage"
                            rules="required"
                    >
                    <b-form-group label="Manage By Student">                       
                    <span  style="margin-right: 23px;margin: 6px;">                        
                        <input name='able' type="radio" :disabled='isSpinner' :state="errors.length > 0 ? false:null"  value="Yes" v-model='moduleData.manageByStudent'/>
                        <label  style="margin: 6px;">Yes</label>
                    </span>
                  
                     <span  style="margin-left: 23px;margin: 6px;">
                      <input type="radio" :disabled='isSpinner' name='notAble' value="No" v-model='moduleData.manageByStudent'/>
                       <label  style="margin: 6px;">No</label>
                    </span>                   
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>          
        </b-card>
        </validation-observer>
        <!-- SELECT DOCS -->
        <b-card title="Select Document" class="select_doc_wrapper">         
            <b-form-group v-show="items.length > 0">            
            <b-form-checkbox 
                name="flavour-3a"  
                value="true"
                v-model="allSelected"               
                :disabled='isSpinner'
                class="top-custom-checkbox"
                @change="toggleAll"
            >
            Document Name
            </b-form-checkbox>           
            <small class="text-danger" v-if='selected.length <= 0 && items.length !== 0'>Please Select Any Doc. for module</small>  
           
            <draggable
            tag="ul"
            :list='items'
            v-show="items.length > 0"
            group="documents"
            class="list-group list-group-flush cursor-move"            
            >
            <b-col style='display:flex' v-for="option in items" :key="option.document_name.id">
            <b-form-checkbox           
                v-model="selected"
                :value="option"                
                name="flavour-3a"  
                @change="toggleDoc"  
                :disabled='isSpinner'       
            />
                {{ option.document_name.name }}
            </b-col>
            </draggable>           
            </b-form-group>
            <p v-show="items.length == 0 && isSpinner==false" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p>
            <!-- ADD NEW DOCUMENT             -->
            <b-form-group>
                <a href="#" :disabled='isSpinner' @click="openAddDocPopUp()">Add New Document</a>
            </b-form-group>  
             <!-- <div v-show='isSpinner==true' class="loading"></div> -->
        </b-card>
        <!-- CREATE DOC -->
        <b-modal
        v-model="modalShow"
        title="Add New Document"
        ok-title="Save"
        id="update_document"
        centered
        cancel-title='Cancel'
        @cancel.prevent='closeCreateDocModule' 
        @ok.prevent='saveDoc()'     
        >
        <!-- <div>              -->
            <!-- <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/> -->
            <!-- <b-card :disable='isSpinner'>   -->
            <validation-observer
            ref="formValidator"          
            >      
                <div class="mb-1">
                    <div v-if="isSpinner" class="loading loading-custom">         
                    <div class="effect-1 effects"></div>
                    <div class="effect-2 effects"></div>
                    <div class="effect-3 effects"></div>     
                    </div>       
                    <b-row>  
                        <b-col md="6"> 
                            <b-form-group label="Document Name">
                            <validation-provider
                            #default="{errors}"
                            name="document Name"
                            rules="required"
                            >  
                            <b-form-input 
                            v-model='docModule.docName' 
                            name='docName' 
                            :disabled='isSpinner==true'
                            placeholder='Enter Text'  
                            :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small> 
                            </validation-provider> 
                            </b-form-group>        
                        </b-col>  
                        <b-col md="6">
                            <b-form-group label="File Type">
                                <validation-provider
                                #default="{errors}"
                                name="Document Type"
                                rules="required"
                                > 
                                <div class="demo-inline-spacing"> 
                                    <!-- <b-form-radio-group name='docType'> -->
                                    <b-form-radio 
                                    name="single"
                                    id='radio1'
                                    type="radio"
                                    value="single" 
                                    :state="errors.length > 0 ? false:null" 
                                    v-model="docModule.docType"     
                                    @change='manageFiles' 
                                    class="mt-0"  
                                    >
                                    <!-- <label class = "full" for="star5" title="Awesome"> -->
                                        Single
                                    </b-form-radio>                         
                                    <b-form-radio  
                                    name="fontAndBack"
                                    id='radio2'
                                    type="radio"
                                    value="fontAndBack"
                                    :state="errors.length > 0 ? false:null" 
                                    v-model="docModule.docType"
                                    @change='manageFiles'
                                    class="mt-0"
                                    >
                                    <!-- <label class="half" for="star4half" title="Pretty good"> -->
                                        Font & Back
                                    </b-form-radio>                          
                                    <small class="text-danger">{{ errors[0] }}</small> 
                                    <!-- </b-form-radio-group> -->
                                </div>
                                </validation-provider>
                            </b-form-group>                      
                        </b-col>                
                    </b-row>  
                    <b-row>
                        <b-col md="4">
                            <b-form-group label="Upload Demo File" class="modal_filetype" title="please select first filetype" >
                                <validation-provider
                                #default="{errors}"
                                name="Document files"
                                > 
                                <b-form-file  accept=" .jpeg, .jpg" multiple :state="errors.length > 0 ? false:null"  @change="handleFileChange($event)" :disabled="isSpinner" placeholder="Upload Demo File"  v-model='docModule.docFile' drop-placeholder="Drop file here..."/> 
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="docModule.errorMessage.selectFirst.length > 0">{{ docModule.errorMessage.selectFirst }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>          
                </div>
            </validation-observer>
        </b-modal>  
       <div v-if="isSpinner==true" class="loading loading-custom">         
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>     
        </div>  
    </div>
</template>
<script>
//FIREBASE IMPORT
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import { BSpinner,BTable,BRow,BCol, BCard,BFormInput,BDropdown,BDropdownItem,BButton,BImg, BFormFile, BTab, BTabs, BFormGroup, BFormRadio, BFormCheckbox, BFormRadioGroup, BFormCheckboxGroup} from 'bootstrap-vue'
import firebase from "firebase"
import draggable from 'vuedraggable'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections,settingsCollectionDocs } from '@/utils/firebaseCollection.js';
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import * as uploadFile from '@/utils/FirebaseQueries/storageQuery/storageQueries.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
extend('required',required)
export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BTable,
        BRow,
        BDropdown,
        BDropdownItem,
        BButton,
        BImg,
        BFormFile,
        BTab,
        BTabs,
        BFormRadio,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormRadioGroup,
        BFormGroup,
        BCard,
        draggable,
        BFormInput,
        BCol,    
        BSpinner
    },
    data(){
        return{
            items:[],
            allSelected:[],
            isSpinner:false,
            selected:[],
            modalShow:false, 
            docModule:{
            id:'',
            docName:'',
            docType:'',
            docFile:[],
            errorMessage:{
                selectFirst:''
            }
            },
            filesSelected:[],
            storageFolder: 'documents',
            moduleData:{
                name:'',
                manageByStudent:''
            }           
        }
    },
    watch: {
    $route: function () {        
        this.getData();          
    }
    },
    created(){
        var self = this;
        this.getData();
        self.$root.$on('saveNewCategory',()=>{           
            if(this.isSpinner==false){
                if(self.$refs.personalInfoForm)
                {
                    self.saveNewDocModule()
                }
            }
        })
        this.$root.$on('cancelNewCategory',()=>{    
            if(this.isSpinner==false){
                if(self.$refs.personalInfoForm)
                {
                    self.$refs.personalInfoForm.reset()
                    self.$router.push({name:'doc-category-mgmt'})
                    this.$root.$emit('editCategory',false)
                }
            }
        })
        self.$root.$on('updateCategory',()=>{
            if(self.isSpinner==false){
                if(self.$refs.personalInfoForm)
                {
                self.updateCategory()
               // self.editCat();
                }
            }
        })       
    },
    methods:{
        getData(){
            var self = this; 
            self.$root.$emit('stopSpinner') 
            self.isSpinner = true
            setTimeout(()=>{                     
            getQuery.getDataFromRootCollection(dbCollections.DOCUMENTS,res=>{ 
              if(res && res.length > 0){                  
                  self.items = [];
                  res.forEach(values=>{                  
                      if(values && values.isDeleted == false){                        
                       self.items.push({                             
                               document_name: {'name':values.documentName,id:values.id},
                               File_Type:values.fileType,                               
                               upload_demo_file:values.docFile,
                               collectionFile:values.docFile,
                               action:''
                          })                         
                       }
                       self.selected = self.items
                  })
                  self.isSpinner = false
                  console.log(self.items,"JYTOIIIi")                   
              }
              this.bindEditData()
              this.toggleDoc();              
            })
            },1000)              
        },
        bindEditData(){
        var self = this;    
        console.log(self.$route,"?")
        self.isSpinner = true;
        if(Object.keys(self.$route.query).length !== 0){                 
                  console.log(self.$route.query,"elf.$router.params.query")
                  let updateId = self.$route.query.editcategoryId;    
                  console.log(updateId,'updateId')          
                  if(updateId !== undefined){
                      getQuery.getDataFromRootCollectionWithDocID(settingsCollectionDocs.DOCUMENTCATEGORY,updateId,res=>{
                          let tempArry = []
                          if(res.documents.length > 0){
                              res.documents.forEach((docId)=>{                                                        
                                  let findIndex = this.selected.findIndex(ele=>{                                     
                                        return ele.document_name.id == docId
                                  })
                                  if(findIndex > -1){
                                     tempArry.push(this.selected[findIndex])                                   
                                  }
                              })                          
                          }
                          this.moduleData.name = res.categoryName;                      
                          this.moduleData.manageByStudent = res.manageByStudent;  
                          this.selected = tempArry; 
                          this.toggleDoc();              
                      })
                  }
                 self.isSpinner = false;
        }else{
        self.isSpinner = false;
        } 
        },
        toggleAll() {
            if(this.items.length > 0){
                    this.items.forEach(val=>{                        
                        if(this.allSelected !== false){
                        val.isSelected= true;
                        this.selected.push(val)
                        }else{
                          val.isSelected= false;
                          this.selected = [];
                        }
                    })
            }
        },
        toggleDoc(){            
            if(this.selected && this.selected.length > 0){
                if(this.items.length == this.selected.length){
                    this.allSelected = true;
                }else{
                    this.allSelected = false;
                }
            }else{
                this.allSelected = false;
            }
        },
        openAddDocPopUp(){
            if(this.isSpinner==false){
                this.modalShow = true;
                this.docModule={
                    id:'',
                    docName:'',
                    docType:'',
                    docFile:[],
                    errorMessage:{
                        selectFirst:''
                    }
                };
            }
        },
        closeCreateDocModule(){
           if(this.isSpinner == false){
            this.modalShow = false;
           }
        },
        addDocStorage(data,cbb){
        // let tempArry = []  
        if(data){
        uploadFile.uploadImageFile(this.storageFolder,data.name,data,res=>{                    
            // tempArry.push(res)                    
            // if(storageRes.length == Number(newDocModule.docFile.length)){ 
                cbb(res)
            // }
        }) 
        }   
        },
        uploadDocumentFile(newDocModule,cb){
        let storageRes = []  
        var self = this;         
        if(newDocModule.docFile.length !== 0){ 
            let fileArry = newDocModule.docFile;
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= fileArry.length){  
                   cb(storageRes) 
                   return;                
               }
                self.addDocStorage(row,(saveRes)=>{
                    console.log(saveRes,"?savedRess?")                    
                    storageRes.push(saveRes)
                    count += 1;
                    uploadFileFunction(fileArry[count]);
                });              
            }
            uploadFileFunction(fileArry[count]);              
        }else{
            cb(storageRes)
        }
        },
        saveDoc(){
          var self = this;
           if(this.isSpinner == false){
            if(self.$refs.formValidator){
                self.$refs.formValidator.validate().then(success => {
                    if (success && this.docModule.errorMessage.selectFirst == ''){                    
                        self.isSpinner = true; 
                        console.log("ASP")
                        self.uploadDocumentFile(this.docModule,resp=>{
                            if(resp){
                                let obj = {
                                        createdAt : firebase.firestore.FieldValue.serverTimestamp(),
                                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                                        isDeleted: false,                                    
                                        documentName: this.docModule.docName,
                                        fileType:this.docModule.docType,
                                        docFile: resp
                                }  
                                inserQuery.addDataToRootCollection(dbCollections.DOCUMENTS,obj,res=>{                              
                                    // self.isSpinner = false;
                                    let updateId={
                                        'id':res.id
                                    }
                                    updateQuery.updateRootCollectionDataByDocId(dbCollections.DOCUMENTS,res.id,updateId,res=>{
                                        console.log(res)
                                        self.isSpinner = false;
                                        self.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Document Add Successfully',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                        })                                                       
                                        self.closeCreateDocModule() 
                                            // self.init()
                                        })                                
                                })                           
                                self.isLoading = false;
                            }
                        })
                    }               
                })
            }
            }
            //else{
        //     this.updateDoc()
        //    } 
        },
        manageFiles(){
          var self = this;
          console.log(this.docModule.docType,"docModule.docType")
          if(this.docModule.docFile.length > 0){
             let files = this.docModule.docFile;
            if(this.docModule.docType !== '' && this.docModule.docType == 'fontAndBack'){
                if(files.length !== 2){
                   this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                   this.docModule.docFile = [];
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
            if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                if(files.length !== 1){
                   this.docModule.errorMessage.selectFirst = `Please select one file again`;
                   this.docModule.docFile = [];
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }     
          }          
        },
        handleFileChange(evt) {
            this.docModule.errorMessage.selectFirst = ''
            let files = evt.target.files;
            if(files && files.length > 0){
                this.filesSelected = files;     
                console.log(this.docModule.docType,"this.docModule.docType")
                if(this.docModule.docType !== '' && this.docModule.docType == 'fontAndBack'){
                    if(files.length !== 2){
                    this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                    this.docModule.docFile = [];
                    }else{
                        this.docModule.errorMessage.selectFirst = ''
                    }
                }
                if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                    if(files.length !== 1){
                    this.docModule.errorMessage.selectFirst = `Please select one file again`;
                    this.docModule.docFile = [];
                    }else{
                        this.docModule.errorMessage.selectFirst = ''
                    }
                } 
                // EXTENSION VALIDATION   
                files.forEach(val=>{
                    console.log(val.type,"???")
                    if(val.type !== 'image/jpeg' && val.type !== 'image/jpg' && val.type !== ".jpeg" && val.type !== ".jpg"){
                    this.docModule.errorMessage.selectFirst = `File allow only jpeg or jpg[upload Again]`; 
                    this.docModule.docFile = []; 
                    }
                })
            }          
        }, 
        saveNewDocModule(){
            var self = this
            self.$refs.personalInfoForm.validate().then(success => {
                if (success) {
                    self.isSpinner = true;
                    if(self.selected.length > 0 && self.items.length !== 0){   
                        let tempArry = [];                     
                        setTimeout(()=>{
                                if(self.items && self.items.length > 0){  
                                    tempArry = [];                                 
                                    self.items.forEach(ele=>{
                                        console.log(ele,"JYOTI")
                                        let findInd = self.selected.findIndex(values=>{
                                            console.log(values.document_name.id,"???",values.document_name)
                                            return values.document_name.id == ele.document_name.id;
                                        })
                                        if(findInd !== -1){
                                            tempArry.push(ele.document_name.id)
                                        }                                       
                                    })                                   
                                }                          
                                let obj = {
                                    'categoryName':this.moduleData.name,
                                    'manageByStudent':this.moduleData.manageByStudent,
                                    'documents':tempArry,
                                    'createdAt':firebase.firestore.FieldValue.serverTimestamp(),
                                    'updatedAt':firebase.firestore.FieldValue.serverTimestamp(),
                                    'isDeleted':false,                                    
                                }  
                                console.log(obj,"JYOTIIIII")                              
                                inserQuery.addDataToRootCollection(settingsCollectionDocs.DOCUMENTCATEGORY,obj,resp=>{
                                    let updateId = {
                                        id : resp.id
                                    }
                                    updateQuery.updateRootCollectionDataByDocId(settingsCollectionDocs.DOCUMENTCATEGORY,resp.id,updateId,res=>{
                                        self.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'Document Add Successfully',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                        })
                                        // this.resetFields()
                                        setTimeout(()=>{
                                        this.allSelected = true;
                                        this.$refs.personalInfoForm.reset();
                                        this.$root.$emit('stopSpinner')
                                        this.isSpinner = false;                                        
                                        this.$router.push({name:'doc-category-mgmt'})                                       
                                        },1000)
                                    })  
                                })                      
                                },1000)  
                        self.$root.$emit('stopSpinner')
                        // self.isSpinner = false;
                    }else{
                         self.$root.$emit('stopSpinner')
                         self.isSpinner = false;
                    }
                }else{
                    self.$root.$emit('stopSpinner')
                    self.isSpinner = false;
                }
            })
        },
        editCat(){
            this.isSpinner = true;
            this.$root.$emit('stopSpinner')
        },
        updateCategory(){
            var self = this
            // self.isSpinner = true;
            self.$refs.personalInfoForm.validate().then(success => {
                if (success) {
                    self.isSpinner = true;
                    if(self.selected.length > 0 && self.items.length !== 0){   
                        let tempArry = [];                     
                        setTimeout(()=>{
                                if(self.items && self.items.length > 0){  
                                    tempArry = [];                                 
                                    self.items.forEach(ele=>{
                                        let findInd = self.selected.findIndex(values=>{
                                            return values.document_name.id == ele.document_name.id;
                                        })
                                        if(findInd !== -1){
                                            tempArry.push(ele.document_name.id)
                                        }                                       
                                    })                                   
                                }                          
                                let obj = {
                                    'categoryName':this.moduleData.name,
                                    'manageByStudent':this.moduleData.manageByStudent,
                                    'documents':tempArry,                                   
                                    'updatedAt':firebase.firestore.FieldValue.serverTimestamp(),                                                                      
                                }                              
                                updateQuery.updateRootCollectionDataByDocId(settingsCollectionDocs.DOCUMENTCATEGORY,self.$route.query.editcategoryId,obj,res=>{      
                                        self.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'Document update Successfully',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                        })
                                        // this.resetFields()
                                         setTimeout(()=>{
                                        this.allSelected = true;
                                        // this.$refs.personalInfoForm.reset();
                                        this.$root.$emit('stopSpinner')
                                        this.isSpinner = false;
                                        this.$router.push({name:'doc-category-mgmt'})
                                        this.$root.$emit('editCategory',false)
                                         },1000)                                     
                                    })                      
                                },1000)  
                        // self.$root.$emit('stopSpinner')
                        // self.isSpinner = false;
                    }else{
                         self.$root.$emit('stopSpinner')
                         self.isSpinner = false;
                    }
                }else{
                    self.$root.$emit('stopSpinner')
                    self.isSpinner = false;
                }
            })
        } 
    }
}
</script>
<style lang="scss">
.per-page-selector {
  width: 170px;
}
.propsObj{
  visibility: hidden;
}
.text-overflow {
  height: 20px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}
.btn-overflow {
  text-decoration: none;
  overflow: hidden;
}
.btn-overflowHidden{
  display: none; 
  text-decoration: none
}


@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>